<template>
	<div class="pdf-change">
		<div class="header acea-row row-between-wrapper" ref="header">
			<div class="black" @click="returnChange()">
				<img src="@assets/images/icon_black.png" alt="" />
			</div>
			<p>查看数据文件</p>
		</div>
		<div class="pdf" v-show="fileType === 'pdf'">
		    <p class="arrow acea-row row-between">
			    <!-- // 上一页 -->
			    <span @click="changePdfPage(0)" class="turn" :class="{grey: currentPage==1}">上一页</span>
			    {{currentPage}} / {{pageCount}}
			    <!-- // 下一页 -->
			    <span @click="changePdfPage(1)" class="turn" :class="{grey: currentPage==pageCount}">下一页</span>
		    </p>
			<!--// 自己引入就可以使用,这里我的需求是做了分页功能,如果不需要分页功能,只要src就可以了 -->
		    <pdf
		      	:src="src" 
		      	:page="currentPage" 
		      	@num-pages="pageCount=$event" 
		      	@page-loaded="currentPage=$event"
		      	@loaded="loadPdfHandler">
		    </pdf>
		</div>
	</div>
</template>
<script type="text/javascript">
import pdf from 'vue-pdf'
export default { 
	components: {pdf},
	data () {
      return {
        currentPage: 0, // pdf文件页码
        pageCount: 0, // pdf文件总页数
        fileType: 'pdf', // 文件类型
        src: '', // pdf文件地址

      }
    },
    created(){
    	// 有时PDF文件地址会出现跨域的情况,这里最好处理一下
	   this.src = pdf.createLoadingTask(this.$route.query.path);
	},
    methods: {
    	//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
		// 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
		changePdfPage (val) {
			// console.log(val)
			if (val === 0 && this.currentPage > 1) {
			  this.currentPage--
			  // console.log(this.currentPage)
			}
			if (val === 1 && this.currentPage < this.pageCount) {
			  this.currentPage++
			  // console.log(this.currentPage)
			}
		},

		// pdf加载时
		loadPdfHandler () {
			this.currentPage = 1 // 加载的时候先加载第一页
		}

    }
} 
</script>


<style type="text/css">
.pdf-change { padding-top:50px; }
.pdf-change .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.pdf-change .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.pdf-change .header .black img {
  width: 100%;
  height: 100%;
}
.pdf-change .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}

.pdf-change .pdf { position:relative; }
.pdf-change .pdf .arrow { position:absolute; top:0px; left:0px; width:100%; z-index:1000; padding:5px 8px; }

</style>